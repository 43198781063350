<template>
  <div>
    <v-container>
      <p class="mb-1">Match each of the terms below to the definitions.</p>

      <p class="mb-6 pl-1">
        <v-row
          v-for="(question, index) in questions"
          :key="question.value"
          no-gutters
          class="mb-n1"
        >
          <v-col class="pt-5" cols="2"> {{ question }}</v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="5">
            <v-select
              v-model="inputs[`question${index + 1}Value`]"
              :items="optionsShuffled"
              item-text="text"
              item-value="value"
              label="Select an option:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UCIrvineQuiz1S1Q9',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
        question2Value: null,
        question3Value: null,
        question4Value: null,
      },
      questions: ['a) safety', 'b) hazard', 'c) risk', 'd) exposure'],
      options: [
        {
          text: 'probability of suffering harm because of exposure',
          value: 'A',
        },
        {
          text: 'coming in contact with a hazard',
          value: 'B',
        },
        {
          text: 'potential source of danger or harm',
          value: 'C',
        },
        {
          text: 'freedom from danger, injury, or damage',
          value: 'D',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
